html, body {
	
}

body {
	background-color: #efefef;
}

#root {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

#logo {
	width: 500px;
	max-width: 90%;
}

.calc-process-name, .calc-materialCategory-name {
	padding: 2px;
}

li .calc-process-name, li .calc-materialCategory-name {
	padding: 4px 16px;
}